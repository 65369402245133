import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';
import { AuthActions } from '@store/auth';

import { AuthenticationService } from './authentication.service';

import { take } from 'rxjs/operators';
import { isPlatform } from '@ionic/angular';
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { GOOGLE_LOGIN } from '@core/constants';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SignInWithApple, SignInWithAppleOptions } from '@capacitor-community/apple-sign-in';

@Injectable({
  providedIn: 'root'
})
export class SocialLoginService {

  constructor(
    private _store: Store,
    private _google: GooglePlus,
    private _authService: AuthenticationService
  ) { }

  /**
   * Function to signin with facebook
   */
  async facebookLogin(): Promise<FacebookLoginResponse> {
    const FACEBOOK_PERMISSIONS = ['email'];
    return await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }) as FacebookLoginResponse;
  }

  /**
   * Function to signin with google
   */
  async googleLogin(): Promise<Record<string, any>> {
    if (!isPlatform('desktop')) {
      const params = isPlatform('android') ? { webClientId: GOOGLE_LOGIN.android.webClientId, offline: true } : {};
      return await this._google.login(params);
    }
    return await new Promise((resolve) => resolve({}));
  }


  /**
   * Function to signin with apple
   */
  async appleLogin(): Promise<Record<string, any>> {
    if (!isPlatform('desktop')) {
      const options: SignInWithAppleOptions = {
        clientId: 'com.pit.wedooit',
        redirectURI: 'https://dev-wedo.displayme.net/',
        scopes: 'email name',
        state: '12345',
        nonce: 'nonce',
      };
      // const params = isPlatform('android') ? { webClientId: GOOGLE_LOGIN.android.webClientId, offline: true } : {};
      return await SignInWithApple.authorize(options);

    }
    return await new Promise((resolve) => resolve({}));
  }

  /**
   * Function to handle login with facebook
   */
  signinWithFB(deviceId?: string) {
    this._store.dispatch(new AuthActions.SigninWithFB({ deviceId }))
      .pipe(take(1))
      .subscribe({
        next: (success) => this._authService.signinSuccess(success),
        error: (error) => this._authService.signinError(error)
      });
  }

  /**
   * Function to handle login with google
   */
  signinWithGoogle(deviceId?: string) {
    this._store.dispatch(new AuthActions.SigninWithGoogle({ deviceId }))
      .pipe(take(1))
      .subscribe({
        next: (success) => this._authService.signinSuccess(success),
        error: (error) => this._authService.signinError(error)
      });
  }

  /**
 * Function to handle login with apple
 */
  signinWithApple(deviceId?: string) {
    this._store.dispatch(new AuthActions.SigninWithApple({ deviceId }))
      .pipe(take(1))
      .subscribe({
        next: (success) => this._authService.signinSuccess(success),
        error: (error) => this._authService.signinError(error)
      });
  }

}
