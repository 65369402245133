import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';


export const httpLoaderFactory = (http: HttpClient) =>
    new TranslateHttpLoader(http, './assets/i18n/', '.json');

export const isInside = (distance: number, radius: number): boolean => {
    if (Number.isNaN(distance) || Number(distance) <= Number(radius)) {
        return true;
    } else {
        return false;
    }
};

export const getDistanceBetweenPoints = (start: { lat: number; lng: number }, end: { lat: number; lng: number }) => {
    const lat1 = start.lat;
    const lon1 = start.lng;
    const lat2 = end.lat;
    const lon2 = end.lng;
    const dLat = toRad((lat2 - lat1));
    const dLon = toRad((lon2 - lon1));
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = 6371 * c;
    return d;
};

export const toRad = (x) => x * Math.PI / 180;

export const sortData = (searchData) => {
    searchData.sort((userA, userB) => userA.distance - userB.distance
    );
};



export const isValidImageURL = (str: string) => !!str.match(/\w+\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);


export const isValidVideoURL = (str: string) => !!str.match(/\w+\.(mp4|3gp|ogg|mpg|avi|m4v)$/gi);

export const _getFileName = (fullpath: string) => fullpath.replace(/^.*[\\\/]/, '');

export const toHHMMSS = (duration, day) => {

    const seconds = Number(duration);
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    let minStr = ''; let dayStr = '';
    if (d > 0) { dayStr = d + day;}
    if (m < 10) { minStr = '0' + m; }

    return dayStr + h + 'h ' + minStr;
};




