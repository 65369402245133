import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConnectionStatus, NetworkService } from './network.service';
import { CacheService } from './cache.service';
import { OfflineManagerService } from './offline-manager.service';
import { API_STORAGE_KEY } from '@core/constants';




@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _http: HttpClient, private _networkService: NetworkService,
    private cacheService: CacheService, private offlineManager: OfflineManagerService
  ) { }

  /**
   * Function to seda data to the server
   *
   * @param url API End Point
   * @param body Payload Data
   */
  post(url: string, body: any, backupPayload?: any, offline?: boolean, key?: string): Observable<any> {
    if ((this._networkService.getCurrentNetworkStatus() === ConnectionStatus.offline) && offline) {
      //return from(this.cacheService.getLocalCache(key));
      this.offlineManager.storeRequest(url, 'POST', body);
      const newdata = { data: backupPayload, error: false };
      return of(newdata);
    } else {
      return this._http.post<any>(url, body)
        .pipe(
          map((res: any) => res),
          catchError(this.handleError())
        );
    }


  }

  /**
   * Function to fetch data from the server
   *
   * @param url API End Point
   */
  get(url: string, offline?: boolean, key?: string): Observable<any> {
    if ((this._networkService.getCurrentNetworkStatus() === ConnectionStatus.offline) && offline) {
      return from(this.cacheService.getLocalCache(`${API_STORAGE_KEY}-${key}`));
    } else {
      return this._http.get(url)
        .pipe(
          map((res: any) => res),
          tap(res => {
            if (offline) { this.cacheService.setLocalCache(`${API_STORAGE_KEY}-${key}`, res); }
          }),
          catchError(this.handleError())
        );
    }
  }

  /**
   * Function to update the data
   *
   * @param url API End Point
   * @param body Payload Data
   */
  put(url: string, body: Record<string, any>): Observable<any> {
    return this._http.put(url, body)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError())
      );
  }

  /**
 * Function to patch the data
 *
 * @param url API End Point
 * @param body Payload Data
 */
  patch(url: string, body: Record<string, any>): Observable<any> {
    return this._http.patch(url, body)
      .pipe(
        map((res: any) => res),
        catchError(this.handleError())
      );
  }

  /**
   * Function to remove the data
   *
   * @param url API End Point
   */
  delete(url: string): Observable<any> {
    return this._http.delete(url, {})
      .pipe(
        map((res: any) => res),
        catchError(this.handleError())
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   */
  private handleError() {
    return (error: any): Observable<any> => throwError(error);
  }

}
