import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENDPOINTS, EObjectTypes } from '@core/constants';
import { Observable, Subject } from 'rxjs';
import { ToastService } from './toast.service';

export enum EUploadStatus {
  pending = 'pending',
  success = 'success',
  error = 'error'
}

export interface IUpload {
  files?: File[];
  fileName?: string;
  filePath?: string;
  fullFilePath?: string;
  loadedSize?: string;
  totalSize?: string;
  isUploading?: boolean;
  progress?: number;
  error?: string;
  success?: Record<string, any>;
  uploadStatus?: EUploadStatus;
};

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  public prevValue = {};

  constructor(
    private _toastService: ToastService,
    private _http: HttpClient,
  ) { }

  public fileUploadHandler(files, objectType: EObjectTypes): Observable<IUpload> {
    const payload = new FormData();
    const upload = new Subject<IUpload>();

    payload.append('objectType', objectType);
    Array.from(files).forEach((file: any) => {
      this.prevValue = { files, fileName: file?.name };
      payload.append('files[]', file);
    });

    this._http
      .post(API_ENDPOINTS.fileUpload, payload, { reportProgress: true, observe: 'events' })
      .subscribe((event: HttpEvent<any>) => {
        this._porgressHandler(event, upload);
      }, (err: Record<string, any>) => {
        const errorMessage = err?.error?.message || err?.message;
        this.prevValue = { ...this.prevValue, uploadStatus: EUploadStatus.error, error: errorMessage };
        upload.next(this.prevValue);
        this._toastService.showToastByMessage(errorMessage, 'danger');
      });
    return upload;
  }

  private _porgressHandler(event: HttpEvent<any>, upload) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const { loaded, total } = event;
        const progress = Math.round(loaded / total * 100);
        const loadedSize = (loaded / 1024 / 1024).toFixed(2);
        const totalSize = (total / 1024 / 1024).toFixed(2);
        const uploadStatus = progress === 100 ? EUploadStatus.success : EUploadStatus.pending;
        const isUploading = uploadStatus === EUploadStatus.success || uploadStatus === EUploadStatus.pending;

        this.prevValue = { ...this.prevValue, uploadStatus, loadedSize, totalSize, progress, isUploading };
        upload.next(this.prevValue);
        break;
      case HttpEventType.Response:
        this.prevValue = { ...this.prevValue, uploadStatus: EUploadStatus.success, success: event.body, progress: 100, isUploading: true };
        upload.next(this.prevValue);
        break;
    }
  }

}
