/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';

import { environment } from '@environment';

import { Store } from '@ngxs/store';
import { HelperService } from '@core/services/helper-service';
import { AuthenticationService, ToastService } from '@core/services';
const ONESIGNAL_NOTIFICATION_URL = 'https://onesignal.com/api/v1/notifications';
const ONESIGNAL_ACCESS_TOKEN = 'Basic YjBjZTNhODQtNTdhNC00ZDgyLTk4ZmMtNmNjMjMxYjU3N2U2';
@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthenticationService,
    private _store: Store,
    private _helperService: HelperService,
    private _toastService: ToastService
  ) { }

  /**
   * Function to intercept all the HTTP calls
   *
   * @param request: HTTP Request
   * @param next: HTTP Handler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { url } = request;
    const { accessToken } = this._store.selectSnapshot((state: Record<string, any>) => state.auth);

    const locale = localStorage.getItem('lang') || 'en';
    const API_BASE_URL = url.match(/assets/) || url.includes('jsonplaceholder') ? '' : environment.apiURL;

    request = request.clone({
      url: `${API_BASE_URL}${url}`,
      setHeaders: {
        Accept: 'application/json',
        locale,
        ...(accessToken && { Authorization: `Bearer ${accessToken}` })

      }
    });

    if (url.includes(ONESIGNAL_NOTIFICATION_URL)) {
      const accessToken1 = url.includes(ONESIGNAL_NOTIFICATION_URL) ?
        ONESIGNAL_ACCESS_TOKEN : '';
      request = request.clone({ headers: request.headers.delete('locale') });
      request = request.clone({
        url: `${url}`,
        setHeaders: {
          Accept: 'application/json',
          ...(accessToken1 && { Authorization: `${accessToken1}` })
        }
      });
    }


    this._helperService.loading(true);
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      finalize(() => {
        this._helperService.loading(false);
      }),
      catchError((error: HttpErrorResponse) => this._handleHttpErrorResponse(error))
    );
  }

  /**
   * Function to handle http error response
   *
   * @param error HttpErrorResponse
   */
  private _handleHttpErrorResponse(error: HttpErrorResponse): Observable<any> {
    this._helperService.loading(false);
    switch (error.status) {
      case 401:
        this._authService.signout();
        if (error?.error?.message.includes('blocked')) {
          this._toastService.showToast(error.error.message, 'danger');
        }
        return of(null);
      case 403:
        return of(null);
      default:
        return throwError(error);
    }
  }

}
