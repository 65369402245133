/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ApiService } from './api.service';
import { ToastService } from './toast.service';
import { API_ENDPOINTS, ROUTING_URLS } from '@core/constants';

import { Store } from '@ngxs/store';
import { AuthActions, ISignin, IToken } from '@store/auth';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { HelperService } from './helper-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private _store: Store,
    private _navCtrl: NavController,
    private _toastService: ToastService,
    private _apiService: ApiService,

    private _helperService: HelperService
  ) { }

  /**
   * Function to handle signin
   *
   * @param payload Login api payload
   */
  signin(payload: ISignin, socialLogin = false): Observable<Record<string, any>> {
    return socialLogin
      ? this._apiService.post(API_ENDPOINTS.socialLogin, payload)
      : this._apiService.post(API_ENDPOINTS.login, payload);
  }

  signinSuccess(success: Record<string, any>) {
    const { auth: { authenticated, accessToken } } = success || { auth: {} };
    if (accessToken) {
      this._helperService.setTokenExpiry(accessToken);
    }
    if (!authenticated) {
      return;
    }
    this._navCtrl.navigateForward(`/${ROUTING_URLS.wedoJoblistingMap}`);
    this._toastService.showToast('login_page.login_success');
  }

  signinError(error: Record<string, any>) {
    (error?.error?.message)
      ? this._toastService.showToastByMessage(error?.error?.message, 'danger')
      : this._toastService.showToast('login_page.form_errors.login_failed', 'danger');
  }

  verificationSuccess(error: Record<string, any>) {
    this._toastService.showToast('login_page.verify_registration_success');
  }

  verificationError(error: Record<string, any>) {
    this._toastService.showToastByMessage(error?.error?.message, 'danger');
  }

  verifyRegistration(payload: IToken) {
    return this._apiService.post(API_ENDPOINTS.verifyRegister, payload);
  }

  /**
   * Function to handle signout
   */
  signout() {

    this._signout();


  }

  private _signout() {
    this._store.dispatch(new AuthActions.Signout())
      .pipe(take(1))
      .subscribe({
        next: async (success) => {

          this._navCtrl.navigateBack(`/${ROUTING_URLS.login}`);
          await FacebookLogin.logout();
        },
        error: (error) => console.error(error)
      });
  }



}


