import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@core/components';

import { ROUTING_URLS } from '@core/constants';
import { AuthGuard, AutoLoginGuard } from '@core/guards';

const routes: Routes = [

  {
    path: 'wedo',
    component: MainComponent,
    children: [
      { path: '', redirectTo: ROUTING_URLS.wedoJoblistingMap, pathMatch: 'full' },
      {
        path: ROUTING_URLS.profileMain,
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
      },
      {
        path: ROUTING_URLS.settings,
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
      },
      {
        path: ROUTING_URLS.joblistingMap,
        loadChildren: () => import('./pages/job-listing-map/job-listing-map.module').then(m => m.JobListingMapModule)
      },
      {
        path: ROUTING_URLS.chat,
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: ROUTING_URLS.favouriteJobListing,
        loadChildren: () => import('./pages/favourite-jobs/favourite-jobs.module').then( m => m.FavouriteJobsPageModule)
      },
      {
        path: ROUTING_URLS.joblisting,
        loadChildren: () => import('./pages/job-listing/job-listing.module').then(m => m.JobListingModule),
      },
      {
        path: ROUTING_URLS.jobDetail,
        loadChildren: () => import('./pages/job-detail/job-detail.module').then(m => m.JobDetailPageModule),
      },
      {
        path: ROUTING_URLS.createJob,
        loadChildren: () => import('./pages/create-job/create-job.module').then(m => m.CreateJobModule)
      },
      {
        path: ROUTING_URLS.jobEdit,
        loadChildren: () => import('./pages/create-job/create-job.module').then(m => m.CreateJobModule)
      },
      {
        path: ROUTING_URLS.kyc,
        loadChildren: () => import('./pages/kyc-verification/kyc-verification.module').then(m => m.KycVerificationModule),
      },
      {
        path: ROUTING_URLS.reviews,
        loadChildren: () => import('./pages/reviews/reviews.module').then(m => m.ReviewsModule),
      },
      {
        path: ROUTING_URLS.offersOrders,
        loadChildren: () => import('./pages/offers-orders/offers-orders.module').then(m => m.OffersOrdersModule),
      },
      {
        path: ROUTING_URLS.offerDetails,
        loadChildren: () => import('./pages/offer-details/offer-details.module').then(m => m.OfferDetailsPageModule)
      },
      {
        path: ROUTING_URLS.makeanOffer,
        loadChildren: () => import('./pages/make-an-offer/make-an-offer.module').then(m => m.MakeAnOfferPageModule)
      },
      {
        path: ROUTING_URLS.offerGivenDetails,
        loadChildren: () => import('./pages/offer-given-details/offer-given-details.module').then(m => m.OfferGivenDetailsPageModule)
      },
      {
        path: ROUTING_URLS.searchNotification,
        loadChildren: () => import('./pages/search-notification/search-notification.module').then( m => m.SearchNotificationPageModule)
      },
      {
        path: ROUTING_URLS.notificationlisting,
        loadChildren: () => import('./pages/notification-listing/notification-listing.module').then( m => m.NotificationListingPageModule)
      },
      {
        path: ROUTING_URLS.noInternet,
        loadChildren: () => import('./pages/internet-connectivity/internet-connectivity.module').then( m => m.InternetConnectivityModule)
      },
      {
        path: ROUTING_URLS.privacyPolicy,
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
      },
      {
        path:ROUTING_URLS.help,
        loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
      },
    ],
    canLoad: [AuthGuard]
  },
  {
    path: ROUTING_URLS.login,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: ROUTING_URLS.verifyEmail,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: ROUTING_URLS.registration,
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule)
  },
  {
    path: ROUTING_URLS.forgotPassword,
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: ROUTING_URLS.resetPassword,
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: ROUTING_URLS.splash,
    loadChildren: () => import('./pages/splash/splash.module').then(m => m.SplashModule),
  },
  {
    path: ROUTING_URLS.resendRegistration,
    loadChildren: () => import('./pages/resend-registration/resend-registration.module').then(m => m.ResendRegistrationPageModule)
  },

  {
    path: '',
    redirectTo: `/${ROUTING_URLS.splash}`,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: `/${ROUTING_URLS.login}`
  },
  {
    path: 'job-detail',
    loadChildren: () => import('./pages/job-detail/job-detail.module').then(m => m.JobDetailPageModule)
  },















];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
