export class AuthStateModel {
  accessToken?: string;
  authenticated?: boolean;
  isFirstLogin?: boolean;
  uuid?: string;
  result?: Record<string, any>;
  type?: string;
  socialLogin?: boolean;
}

export interface ISignin {
  username?: string;
  password?: string;
  type?: 'facebook' | 'google' | 'apple';
  code?: string;
  deviceId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface IToken {
  token: string;
}
