import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { BehaviorSubject, fromEvent, merge, Observable, of } from 'rxjs';
import { Platform, isPlatform } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { ToastService } from './toast.service';

export enum ConnectionStatus {

    online,
    offline,
    init
}

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    private status$: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.init);


    constructor(private network: Network, private _toastService: ToastService, private plt: Platform) {
        this.plt.ready().then(() => {
            if (isPlatform('ios') || isPlatform('android')) {

                if (!this.network.type || this.network.type === 'none') {
                    this.updateNetworkStatus(ConnectionStatus.offline, true);
                }

                this.network.onConnect().subscribe(() => {

                    this.updateNetworkStatus(ConnectionStatus.online);
                });
                this.network.onDisconnect().subscribe(() => {

                    this.updateNetworkStatus(ConnectionStatus.offline);
                });
            } else {
                this.initConnectivityMonitoring();
            }
        });
    }



    private initConnectivityMonitoring() {

        if (!window || !navigator || !('onLine' in navigator)) {
            return;
        }

        const browserNw$ = merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        ).pipe(map(() => navigator.onLine));


        browserNw$.subscribe((result) => {
            console.log(result);
            if (result) {

                this.updateNetworkStatus(ConnectionStatus.online);


            } else {

                this.updateNetworkStatus(ConnectionStatus.offline);

            }

        });


    }

    private async updateNetworkStatus(status: ConnectionStatus, hideMsg?: boolean) {
        this.status$.next(status);
        if (!hideMsg) {
            const lang = localStorage.getItem('lang');
            const disconnectedMsg = lang?.includes('en') ? 'Internet Disconnected' : 'Internet getrennt';

            const connection = status === ConnectionStatus.offline ? 'Offline' : 'Online';



            if (status === ConnectionStatus.offline) {
                this._toastService.showToastByMessage(disconnectedMsg, 'warning');
            } else {
                this._toastService.showToast('general.internet_connected', 'success');
            }
        }



    }

    public getNetworkStatus(): Observable<ConnectionStatus> {
        return this.status$;
    }

    public getCurrentNetworkStatus(): ConnectionStatus {
        return this.status$.getValue();
    }
}
