/* eslint-disable @typescript-eslint/naming-convention */
import { Component, NgZone, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Plugins } from '@capacitor/core';
import { Globalization } from '@ionic-native/globalization/ngx';
import { AlertController, IonRouterOutlet, isPlatform, NavController, Platform } from '@ionic/angular';
import { AndroidPermissionService } from '@core/services/android-file-permission-service';
import { HelperService } from '@core/services/helper-service';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { CacheService } from '@core/services/cache.service';
import { ROUTING_URLS } from '@core/constants';
import OneSignal from 'onesignal-cordova-plugin';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
//import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
//import { OneSignal } from '@ionic-native/onesignal/ngx';
const { App, LocalNotifications } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public loadingSubject$ = this._helperService.loadingSubject;

  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  private _previousUrl;
  private _currentUrl;
  constructor(
    private _zone: NgZone,
    private _deeplinks: Deeplinks,
    private _platform: Platform,
    private _navCtrl: NavController,
    private _translate: TranslateService,
    private _globalization: Globalization,
    private platform: Platform,
    private androidPermissionService: AndroidPermissionService,
    private _helperService: HelperService,
    private storage: CacheService,
    private _location: Location,
    private alertController: AlertController,
    private _router: Router,
    private statusBar: StatusBar,
    private zone: NgZone
    //  private oneSignal: OneSignal,
    //  private localNotifications: LocalNotifications
  ) {
    this._translate.addLangs(['en', 'fr', 'de', 'it']);
    this._translate.setDefaultLang('en');
    this._initializeApp();
    this._backbutton();
    this.storage.init();
    if (isPlatform('ios') || isPlatform('android')) {
      this.OneSignalInit();
    }
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this._previousUrl = this._currentUrl;
        this._currentUrl = event.url;
      });

  }

  private _initializeApp(): void {
    this._platform.ready().then(() => {
      this._initDeepLink();
      this._getDeviceLanguage();
      if (this._platform.is('android')) {
        this.androidPermissionService.requestNecessaryPermissions();
      }
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#00000020');
        this.statusBar.styleLightContent();
      }

      this._helperService.isSessionExpired().subscribe((val) => {
        if (val) {
          this._navCtrl.navigateBack(`/${ROUTING_URLS.login}`);
        }

      });

    });
  }

  private _getDeviceLanguage(): void {
    this._globalization.getPreferredLanguage()
      .then(res => {
        this._useLang(res?.value || 'en');
      })
      .catch(e => {
        if (window.Intl && typeof window.Intl === 'object') {
          this._useLang(navigator?.language || 'en');
        }
      });
  }

  private _useLang(language: string) {
    const lang = language.match(/en|fr|de|it/) ? language.match(/en|fr|de|it/)[0] : 'en';
    localStorage.setItem('lang', lang);
    this._translate.use(lang);
  }

  private _initDeepLink(): void {
    if (isPlatform('android')) {
      this._deeplinks.routeWithNavController(this._navCtrl, {})
        .subscribe((match: Record<string, any>) => {
          this._zone.run(() => {
            const url = match?.$link?.url?.split('.net').pop();
            this._navCtrl.navigateForward(url);
          });
        }, nomatch => {
          console.error(nomatch);
        });
    } else if (isPlatform('ios')) {
      App?.addListener('appUrlOpen', (match: Record<string, any>) => {
        this._zone.run(() => {
          const url = match?.url?.split('.net').pop();
          if (url) {
            setTimeout(() => {
              this._navCtrl.navigateForward(url);
            }, 1500);
          }
        });
      });
    }
  }

  private _backbutton(): void {
    this.platform.backButton.subscribeWithPriority(1, () => {

      if (
        this._location.isCurrentPathEqualTo(
          `/${ROUTING_URLS.wedoJoblistingMap}`
        ) ||
        this._location.isCurrentPathEqualTo(
          `/${ROUTING_URLS.profile}`
        ) ||
        this._location.isCurrentPathEqualTo(
          `/${ROUTING_URLS.wedoJoblisting}`
        ) ||
        this._location.isCurrentPathEqualTo(
          `/${ROUTING_URLS.wedoCreateJob}`
        )
        ||
        this._location.isCurrentPathEqualTo(
          `/${ROUTING_URLS.wedoChat}`

        )
        ||
        this._location.isCurrentPathEqualTo(
          `/${ROUTING_URLS.login}`

        )
      ) {
        this.showExit();

      } else {

        if (this._currentUrl) {
          const url = this._getParameterByName('source', this._currentUrl);

          if (this._currentUrl === `/wedo/${ROUTING_URLS.searchNotification}`
            && this._previousUrl.includes(`/${ROUTING_URLS.wedoJoblisting}`)) {

            this._navCtrl.navigateForward(`/${ROUTING_URLS.profile}`);
          } else {
            this._navCtrl.navigateForward(url ? `/${url}` : (this._previousUrl ? this._previousUrl : `/${ROUTING_URLS.wedoJoblistingMap}`));
          }

        } else {

          this._navCtrl.back();
        }

      }
    }
    );
  }

  async showExit() {
    const alert = await this.alertController.create({
      header: this._translate.instant('back_button.header'),

      buttons: [

        {
          text: this._translate.instant('back_button.cancel'),
          handler: (res) => {
            alert.dismiss();
          }
        },
        {
          text: 'Ok',
          handler: (res) => {
            alert.dismiss();
            App.exitApp();
          }
        }

      ]
    });
    alert.present();
  }
  public async OneSignalInit(): Promise<void> {




    OneSignal.setAppId('91c4df83-0b30-4307-8069-094392073daa');

    OneSignal.setNotificationOpenedHandler((jsonData) => {

      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      if (isPlatform('ios')) {
        this.zone.run(() => {
          setTimeout(() => {
            const url = jsonData.notification.launchURL.replace(/^.*\/\/[^\/]+/, '');
            console.log(url);
            this._navCtrl.navigateForward(url);
          }, 10);
        });
      }

    });
    OneSignal.addSubscriptionObserver((rsult) => {

      // console.log(rsult);
    });


    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      //console.log('User accepted notifications: ' + accepted);

    });



    OneSignal.setNotificationWillShowInForegroundHandler(async (jsonData) => {
      jsonData.complete();
      console.log('notification received: ' + JSON.stringify(jsonData));
      //console.log(jsonData.getNotification()?.title);
      if (isPlatform('ios')) {
        const notifs = await LocalNotifications.schedule({
          notifications: [
            {
              title: jsonData.getNotification()?.title,
              body: jsonData.getNotification()?.body,
              id: 1,
              schedule: { at: new Date(Date.now() + 400) },
              sound: jsonData.getNotification()?.sound,
              attachments: null,
              actionTypeId: '',
              extra: { url: jsonData.getNotification()?.launchURL },

            },
          ],


        });


      }


    });

    LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      if (notification.notification.extra.url) {
        setTimeout(() => {
          this.zone.run(() => {
            const url = notification.notification.extra.url.replace(/^.*\/\/[^\/]+/, '')
            //console.log()
            this._navCtrl.navigateForward(url);
          }, 10);
        });
      }

    });
  }


  private _getParameterByName(name: string, url: string) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) { return null; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

}
