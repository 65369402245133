import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private _translate: TranslateService, private _toastController: ToastController) { }

  /**
   * Function to show toast
   *
   * @param translationKey Translation key | Message
   * @param color 'success' (default) | 'danger'
   * @param duration Duration
   */
  async showToast(translationKey: string, color = 'success', duration = 3000) {
    const message = this._translate.instant(translationKey);
    const toast = await this._toastController.create({ position: 'top', message, color, duration, mode: 'md' });
    toast.present();
  }

  async showToastByMessage(message: string, color = 'success', duration = 3000) {
    const toast = await this._toastController.create({ position: 'top', message, color, duration, mode: 'md' });
    toast.present();
  }

  async showToastByMessageMiddle(message: string, color = 'success', duration = 1000) {
    const toast = await this._toastController.create({ position: 'bottom', message, cssClass: 'tabs-bottom', color, duration, mode: 'md' });
    toast.present();
  }

}
