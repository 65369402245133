import { BehaviorSubject, Observable, timer } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    public loadingSubject = new BehaviorSubject(false);
    public notificationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private sessionExpiryObservable: Observable<number>;
    private sessionExpiry$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        public loadingController: LoadingController,
        public toastController: ToastController,
        private _zone: NgZone
    ) { }

    loading(value: boolean) {
        this._zone.run(() => this.loadingSubject.next(value));
    }

    public async showLoader(text: string, className?: string): Promise<any> {
        const loading = await this.loadingController.create({
            cssClass: className,
            message: text,
        });
        await loading.present();
    }

    public hideLoader(): void {
        this.loadingController.dismiss();
    }

    public isSessionExpired(): BehaviorSubject<boolean> {
        return this.sessionExpiry$;
    }

    public setTokenExpiry(token: string) {
        if (!token) {
            return;
        }
        const userData = this.parseJwtToken(token);
        this.sessionExpiryObservable = timer(new Date(userData.exp * 1000));
        this.sessionExpiryObservable.subscribe((val) => {
            this.sessionExpiry$.next(true);
        });
    }

    parseJwtToken(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64));
        return JSON.parse(jsonPayload);
    }

}
