import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { ROUTING_URLS } from '../constants';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(private _store: Store, private _navCtrl: NavController) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const { authenticated } = this._store.selectSnapshot((state: Record<string, any>) => state.auth);
    if (authenticated) {
      this._navCtrl.navigateForward(`/${ROUTING_URLS.wedoJoblistingMap}`);
      return false;
    }
    return true;
  }

}
