import { Injectable } from '@angular/core';
import { Observable, from, of, forkJoin } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CacheService } from './cache.service';


const STORAGE_REQ_KEY = 'wedo_lcache';

interface StoredRequest {
    url: string;
    type: string;
    data: any;
    time: number;
    id: string;
}

@Injectable({
    providedIn: 'root'
})
export class OfflineManagerService {

    constructor(private cacheService: CacheService, private http: HttpClient) { }
    getDataFromCache(key: string): Observable<any> {


        return from(this.cacheService.getLocalCache(key));
    }
    checkForEvents(): Observable<any> {


        return from(this.cacheService.getLocalCache(STORAGE_REQ_KEY)).pipe(
            switchMap(storedOperations => {
                const storedObj = JSON.parse(storedOperations);
                if (storedObj && storedObj.length > 0) {
                    return this.sendRequests(storedObj).pipe(
                        finalize(() => {


                            this.cacheService.removeLocalCache(STORAGE_REQ_KEY);
                        })
                    );
                } else {
                    console.log('no local events to sync');
                    return of(false);
                }
            })
        );
    }

    storeRequest(url, type, data, id: string = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)) {
        const fdata = this.getFormData(data);

        const action: StoredRequest = {
            url,
            type,
            data: fdata,
            time: new Date().getTime(),
            id
        };

        return this.cacheService.getLocalCache(STORAGE_REQ_KEY).then(storedOperations => {
            let storedObj = JSON.parse(storedOperations);

            if (storedObj) {
                const selUrl = storedObj.findIndex((ele) => ele.url === action.url);
                if (selUrl === -1) {
                    storedObj.push(action);
                } else {
                    storedObj.splice(selUrl, 1);
                    storedObj.push(action);
                }

            } else {
                storedObj = [action];
            }

            return this.cacheService.setLocalCache(STORAGE_REQ_KEY, JSON.stringify(storedObj));
        });
    }

    sendRequests(operations: StoredRequest[]) {
        const obs = [];

        for (const op of operations) {
            console.log('Make one request: ', op);
            const oneObs = this.http.request(op.type, op.url, { body: this.createFormData(JSON.parse(op.data)) });
            obs.push(oneObs);
        }


        return forkJoin(obs);
    }

    getFormData(formData) {
        const object = {};
        formData.forEach((value, key) => {
            // Reflect.has in favor of: object.hasOwnProperty(key)
            if (!Reflect.has(object, key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        return JSON.stringify(object);
    }

    createFormData(item) {
        const formData = new FormData();
        const keys = Object.keys(item);
        keys.forEach(key => {
            formData.append(key, item[key]);
        });
        return formData;
    }

}
