import { ISignin, IToken } from './auth.model';

export namespace AuthActions {
  export class Signin {
    static readonly type = '[Auth] Signin';
    constructor(public payload: ISignin, public socialLogin?: boolean) { }
  }

  export class SigninWithFB {
    static readonly type = '[Auth] Signin with facebook';
    constructor(public payload: { deviceId: string }) { }
  }

  export class SigninWithGoogle {
    static readonly type = '[Auth] Signin with google';
    constructor(public payload: { deviceId: string }) { }
  }
  export class SigninWithApple {
    static readonly type = '[Auth] Signin with apple';
    constructor(public payload: { deviceId: string }) { }
  }

  export class Signout {
    static readonly type = '[Auth] Signout';
  }

  export class VerifyRegistration {
    static readonly type = '[Auth] VerifyRegistration';
    constructor(public payload: IToken) { }
  }


}
