// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://demo.mypits.org:15515/api/1.0',  //'http://api-wedo.dev.displayme.net/api/1.0'
  domainUrl:  'https://test-wedo.displayme.net',
  firebaseConfig: {
    apiKey: 'AIzaSyCSU2vJz1IwWwyytEaXCa3XTj1LBj2CfXs',
    authDomain: 'wedooit-d3422.firebaseapp.com',
    databaseURL: 'https://wedooit-d3422-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'wedooit-d3422',
    storageBucket: 'wedooit-d3422.appspot.com',
    messagingSenderId: '678053001868',
    appId: '1:678053001868:web:215e46ce67b865c69159a9',
    measurementId: 'G-WH68GYP05Z'
  },
  countryJson: [
    {
      name: 'Germany',
      dialCode: '+49',
      isoCode: 'DE',
      flag: 'https://www.countryflags.io/DE/flat/64.png'
    },
    {
      name: 'Switzerland',
      dialCode: '+41',
      isoCode: 'CH',
      flag: 'https://www.countryflags.io/CH/flat/64.png'
    },
    {
      name: 'India',
      dialCode: '+91',
      isoCode: 'IN',
      flag: 'https://www.countryflags.io/IN/flat/64.png'
    },
    {
      name: 'Italy',
      dialCode: '+39',
      isoCode: 'IT',
      flag: 'https://www.countryflags.io/IT/flat/64.png'
    },
    {
      name: 'France',
      dialCode: '+33',
      isoCode: 'FR',
      flag: 'https://www.countryflags.io/FR/flat/64.png'
    }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
