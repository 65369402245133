import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
@Injectable({
  providedIn: 'root'
})
export class AndroidPermissionService {

  constructor(private androidPermissions: AndroidPermissions) {


  }

  public requestNecessaryPermissions(): any {
    // Change this array to conform with the permissions you need
    const androidPermissionsList = [
      // this.androidPermissions.PERMISSION.CAMERA,
      this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
      //  this.androidPermissions.PERMISSION.ACCESS_MEDIA_LOCATION,
      //  this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
      //  this.androidPermissions.PERMISSION.READ_PHONE_STATE,
      // this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE,
      //   this.androidPermissions.PERMISSION.READ_MEDIA_VIDEO,
    ];
    return this.androidPermissions.requestPermissions(androidPermissionsList);
  }
}

