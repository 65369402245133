import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-device-navigation',
  templateUrl: './device-navigation.component.html',
  styleUrls: ['./device-navigation.component.scss'],
})
export class DeviceNavigationComponent implements OnInit {

  constructor(private _route: Router) { }

  ngOnInit() { }

  public highlightButton(url: string): boolean {
    if (this._route.url.includes(`/wedo/${url}`)) {
      return true;
    }
    return false;
  }

}
