import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';


@Injectable({
    providedIn: 'root'
})
export class CacheService {
    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        await this.storage.create();
    }

    public setLocalCache(key: string, data: any) {
        this.storage.set(key, data);
    }

    public getLocalCache(key: string) {
        return this.storage.get(key);
    }

    public removeLocalCache(key: string) {
        return this.storage.remove(key);
    }
}
