import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AuthenticationService, SocialLoginService } from '@core/services';
import { AuthStateModel, AuthActions } from './index';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FacebookLoginResponse } from '@capacitor-community/facebook-login';


@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    accessToken: '',
    authenticated: false,
    uuid: '',
    result: null
  }
})
@Injectable()
export class AuthState {
  constructor(
    private _socialLogin: SocialLoginService,
    private _authService: AuthenticationService
  ) { }

  @Selector()
  static accessToken(state: AuthStateModel) {
    return state.accessToken;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel) {
    return state.authenticated;
  }

  @Selector()
  static isFirstLogin(state: AuthStateModel) {
    return state.isFirstLogin;
  }

  @Action(AuthActions.Signin)
  signin(
    { patchState }: StateContext<AuthStateModel>,
    { payload, socialLogin }: AuthActions.Signin
  ) {
    return this._authService.signin(payload, socialLogin)
      .pipe(
        tap((result: Record<string, any>) => {
          const { data } = result;
          patchState({
            accessToken: data?.access_token || '',
            authenticated: !!data?.access_token,
            isFirstLogin: data?.is_first_login,
            uuid: data?.uuid || '',
            result,
            type: payload.type,
            socialLogin
          });
        })
      );
  }

  @Action(AuthActions.SigninWithFB)
  async signinWithFB(
    { dispatch }: StateContext<AuthStateModel>,
    { payload }: AuthActions.Signin
  ) {
    const result = await this._socialLogin.facebookLogin() as FacebookLoginResponse;
    const { accessToken: { token } } = result || { accessToken: {} };
    return await dispatch(new AuthActions.Signin({ type: 'facebook', code: token, deviceId: payload.deviceId }, true)).toPromise();
  }

  @Action(AuthActions.SigninWithGoogle)
  async signinWithGoogle(
    { dispatch }: StateContext<AuthStateModel>,
    { payload }: AuthActions.Signin
  ) {
    const result = await this._socialLogin.googleLogin() as Record<string, any>;
    const { idToken, accessToken } = result || {};
    return await dispatch(new AuthActions.Signin({ type: 'google', code: accessToken, deviceId: payload.deviceId }, true)).toPromise();
  }
  @Action(AuthActions.SigninWithApple)
  async signinWithApple(
    { dispatch }: StateContext<AuthStateModel>,
    { payload }: AuthActions.Signin
  ) {
    const result = await this._socialLogin.appleLogin();

    const { authorizationCode, email, givenName, familyName } = result.response || {};

    return await dispatch(
      new AuthActions.Signin({
        type: 'apple',
        code: authorizationCode,
        deviceId: payload.deviceId,
        firstName: givenName,
        lastName: familyName,
        email
      }, true)).toPromise();
  }

  @Action(AuthActions.Signout)
  logout({ setState }: StateContext<AuthStateModel>) {
    setState({
      accessToken: '',
      authenticated: false,
      uuid: '',
      socialLogin: false,
      type: ''
    });
  }

  @Action(AuthActions.VerifyRegistration)
  verifyRegistration({ patchState }: StateContext<AuthStateModel>,
    { payload }: AuthActions.VerifyRegistration) {
    return this._authService.verifyRegistration(payload);
  }

}
