export const ROUTING_URLS = {
  login: 'login',
  logout: 'logout',
  verifyEmail: 'verify-email',
  profile: 'wedo/profile',
  profileMain: 'profile',
  profileEdit: 'profile/edit',
  settings: 'settings',
  changePassword: 'wedo/settings/change-password',
  transactionsOverview: 'transactions-overview',
  transactionDetail: 'transaction-detail',
  help: 'help',
  registration: 'registration',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  splash: 'splash',
  joblistingMap: 'jobs_map',
  wedoJoblistingMap: 'wedo/jobs_map',
  chat: 'chat',
  wedoChat: 'wedo/chat',
  favouriteJobListing: 'favourite-jobs',
  joblisting: 'jobs_list',
  wedoJoblisting: 'wedo/jobs_list',
  jobDetail: 'job-detail',
  jobEdit: 'job-edit/:id',
  createJob: 'create-job',
  wedoCreateJob: 'wedo/create-job',
  makeanOffer: 'make-an-offer',
  kyc: 'kyc',
  wedoKYC: 'wedo/kyc',
  reviews: 'reviews',
  wedoReviews: 'wedo/reviews',
  offersOrders: 'offers_orders',
  wedooffersOrders: 'wedo/offers_orders',
  wedoOffersReceived: 'wedo/offers_orders/view-offers/1',
  wedoOffersGiven: 'wedo/offers_orders/view-offers/2',
  wedoOrdersAdded: 'wedo/offers_orders/orders/1',
  wedoOrdersCurrentJobs: 'wedo/offers_orders/orders/2',
  wedoOrdersFinished: 'wedo/offers_orders/orders/3',
  wedoOrdersDraft: 'wedo/offers_orders/orders/4',
  wedoOrdersCurrentOffers: 'wedo/offers_orders/orders/5',
  wedoOrdersPendingForReview: 'wedo/offers_orders/orders/6',
  wedoOrdersSentForReview: 'wedo/offers_orders/orders/7',
  wedoOrdersCompletedbyMe: 'wedo/offers_orders/orders/8',
  wedoOrdersCompletedForMe: 'wedo/offers_orders/orders/9',
  offerDetails: 'offer-details',
  offerGivenDetails: 'offer-given-details',
  profilePublic: 'profile/public',
  resendRegistration: 'resend-registration',
  rating: 'rating',
  profileReport: 'profile/report',
  searchNotification: 'search-notification',
  notificationlisting: 'notification_list',
  noInternet:'noInternet',
  privacyPolicy:'privacy-policy',
  termsAndConditions: 'terms-and-conditions'
};

export const API_ENDPOINTS = {
  login: '/secured/login',
  socialLogin: '/secured/social/login',
  register: '/register/user',
  forgotPassword: '/secured/forgot-password',
  resetPassword: '/secured/reset-password',
  getProfile: '/user/get-profile',
  fileUpload: '/general/upload',
  getDefaultList: '/general/get-defaults',
  getGeneralList: '/general/category/{category}',
  updateProfile: '/user/update-profile',
  verifyRegister: '/secured/verify-token/register',
  verifypassword: '/secured/verify-token/password',
  changePassword: '/secured/change-password',
  jobListing: '/job/list/',
  createJob: '/job/create',
  jobs: '/job/list',
  offers: '/job/offer',
  favouriteJob: '/job/favourites',
  favouriteJobListing: '/job/favourites/list',
  jobDetails: '/job/get-detail/',
  jobUpdate: '/job/update',
  kycUpdate: '/user/kyc-update',
  publicProfile: '/user/get-profile/',
  makeOffer: '/job/make-offer',
  editOffer: '/job/edit-offer',
  resendRegistration: '/register/resend',
  offerDetails: '/job/get-offer-detail/',
  acceptOffer: '/job/accept-offer',
  rejectOffer: '/job/reject-offer',
  changeStatus: '/change-status',
  download: '/general/download',
  ratingList: '/rating/list/',
  rating: '/job/rate',
  submitReport: '/user/report-to-admin',
  reportJob: '/job/report-to-admin',
  reportJobReasons: '/job/report/reasons',
  reportReasons: '/user/user-report/reasons',
  transactionOverview: '/transaction/overview',
  transactionList: '/transaction/list/',
  transactionDetails: '/transaction/',
  transactionInvoiceDetails: '/transaction/invoice/',
  notificationSettings: '/notification/settings',
  userNotifications: '/user/notifications',
  readStatus: '/notification/read-status',
  twilioOtpGenerate :'/otp/generate',
  twilioOtpVerify :'/otp/verify',
  searchText: '/search/text',
  categorySearch:'/search/category',
  categoryJob:'/search/category/job',
  saveSearch: '/search/save',
  saveSearchListing: '/search/get-list/',
  savedDetails: '/search/get-detail/',
  updateSearchDetails: '/search/update/',
  deleteSearchDetails: '/search/delete/'
};

export const GOOGLE_LOGIN = {
  android: {
    webClientId: '678053001868-5od57g1j22c3cjqt31fjuespnmf0tunc.apps.googleusercontent.com'
  }
};

export enum EObjectTypes {
  profileImage = 'profileImage',
  jobCoverImage = 'jobCoverImage',
  userCertificate = 'userCertificate',
  userReference = 'userReference',
  jobDocuments = 'jobDocuments'
};

export enum ECategoryTypes {
  industry = 'industry',
  education = 'education'
};

export enum EJobTypes {
  draft = 'draft',
  open = 'open',
  currentJobs = 'current-jobs',
  currentOffers = 'current-offers',
  completed = 'completed',
  allOpen = 'all-open',
  pendingForReview = 'pending-for-review',
  sentForReview = 'sent-for-review',
  completedByMe = 'completed-by-me',
  completedForMe = 'completed-for-me'
};

export enum EOfferTypes {
  received = 'received',
  given = 'given',
};

export const APP_CONSTANT = {
  profile: 'PROFILE',
  jobs: 'JOBS',
  offers: 'OFFERS',
  transaction:'TRANSACTION'
};

export const API_STORAGE_KEY = 'wedo_api_cache';
