import { IonicModule } from '@ionic/angular';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Geolocation } from '@ionic-native/geolocation/ngx';

import { AuthState } from '@store/auth';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { DeviceNavigationComponent, MainComponent } from './components';
import { HttpConfigInterceptor } from './interceptors/http-config.interceptor';

@NgModule({
  declarations: [DeviceNavigationComponent, MainComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    IonicModule,
    NgxsModule.forRoot([AuthState]),
    NgxsStoragePluginModule.forRoot({ key: ['auth.accessToken', 'auth.authenticated', 'auth.uuid'] })
  ],
  providers: [Geolocation,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ]
})
export class CoreModule { }
